import React from 'react';
import { Link } from 'gatsby';

const PaginationButtons = ({ currentPage, numOfPages, urlPath }) => {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === numOfPages;
  const previousPage =
    currentPage - 1 === 1 ? '' : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();
  return (
    <nav
      className='bg-white px-4 py-3 flex items-center justify-between border-t border-blue-gray-200 sm:px-6'
      aria-label='Pagination'
    >
      <div className='flex-1 flex justify-center font-osans'>
        {!isFirstPage && (
          <Link
            to={`${urlPath}/${previousPage}`}
            rel='prev'
            className='relative inline-flex items-center px-4 py-2 border border-blue-gray-300 text-sm font-medium rounded-md text-blue-gray-700 bg-white hover:bg-blue-gray-50'
          >
            Previous
          </Link>
        )}
        {!isLastPage && (
          <Link
            to={`${urlPath}/${nextPage}`}
            rel='next'
            className='ml-3 relative inline-flex items-center px-4 py-2 border border-blue-gray-300 text-sm font-medium rounded-md text-blue-gray-700 bg-white hover:bg-blue-gray-50'
          >
            Next
          </Link>
        )}
      </div>
    </nav>
  );
};

export default PaginationButtons;
