import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout/layout.component';
import DisplayPostContainer from '../components/display-post/display-post-container.component';
import DisplayPost from '../components/display-post/display-post.component';
import PaginationButtons from '../components/pagination-buttons/pagination-buttons.component';
import Seo from '../components/seo/seo.component';

const Blogs = ({ data, pageContext }) => {
  const { currentPage, numOfPages } = pageContext;
  const { nodes: blogsNodes } = data.blogs;
  return (
    <Layout>
      <Seo
        title={'const community JavaScript lectures!'}
        description={'Read, try and practice!'}
      />
      <DisplayPostContainer>
        <DisplayPost
          headlineTitle='Community Articles'
          headlineDesc='Read, try and practice!'
          containerStyles='mb-20'
          headlineStyles='text-fuchsia-700'
          posts={blogsNodes}
        />
        <PaginationButtons
          currentPage={currentPage}
          numOfPages={numOfPages}
          urlPath={'/blogs'}
        />
      </DisplayPostContainer>
    </Layout>
  );
};

export default Blogs;

export const query = graphql`
  query ListPaginatedBlogsQuery($skip: Int!, $limit: Int!) {
    blogs: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___date] }
      limit: $limit
      skip: $skip
      filter: { fileAbsolutePath: { regex: "/blogs/" } }
    ) {
      nodes {
        id
        frontmatter {
          title
          post
          date(formatString: "MMMM Do, YYYY")
          dateTime: date
          description
          author
          slug
          coverImage
        }
      }
    }
  }
`;
